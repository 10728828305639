import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`26th September 2019`}</p>


    <h2 {...{
      "id": "new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#new-features",
        "aria-label": "new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`You can now create a `}<inlineCode parentName="p">{`Sampler`}</inlineCode>{` from a specification string such as `}<inlineCode parentName="p">{`random=0.1`}</inlineCode>{` and `}<inlineCode parentName="p">{`rate-limited=10`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2108"
          }}>{`#2108`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`This is going to be useful when you want to make the sampling policy of `}<inlineCode parentName="li">{`Brave{Client,Server}`}</inlineCode>{` or `}<inlineCode parentName="li">{`Logging{Client,Server}`}</inlineCode>{` configurable from external sources such as a configuration file or a system property.`}</li>
        </ul>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Sampler<Object> randomSampler = Sampler.of("random=0.1");
Sampler<Object> rateLimitedSampler = Sampler.of("rate-limited=10");
Sampler<Object> alwaysSampler = Sampler.of("always");
Sampler<Object> neverSampler = Sampler.of("never");
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now specify the `}<inlineCode parentName="p">{`Sampler`}</inlineCode>{` specification string in the `}<inlineCode parentName="p">{`com.linecorp.armeria.verboseExceptions`}</inlineCode>{` system property. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2105"
          }}>{`#2105`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2111"
          }}>{`#2111`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`From this release, the default value of this property is `}<inlineCode parentName="li">{`rate-limited=10`}</inlineCode>{` which means the stack trace of the exceptions will be recorded at the maximum rate of 10 exceptions/sec. Previously, the default value of this property was `}<inlineCode parentName="li">{`false`}</inlineCode>{`, which eliminates all stack traces, which gave our users a hard time figuring out why.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now specify a common path prefix using `}<inlineCode parentName="p">{`@PathPrefix`}</inlineCode>{` annotation in annotated services. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2031"
          }}>{`#2031`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2099"
          }}>{`#2099`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`@PathPrefix("/users")
public class MyUserService {
    @Get("/{id}") // Mapped to '/users/{id}'
    @ProducesJson
    public User getUser(@Param int id) { ... }
    ...
}

Server server = new ServerBuilder()
    .annotatedService(new MyUserService())
    .build();
`}</code></pre>
      </li>
    </ul>
    <h2 {...{
      "id": "bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#bug-fixes",
        "aria-label": "bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`Fixed a memory leak in `}<inlineCode parentName="li">{`HealthCheckService`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2110"
        }}>{`#2110`}</a></li>
      <li parentName="ul">{`Prometheus and Dropwizard Metrics are now optional dependencies in `}<inlineCode parentName="li">{`armeria-spring-boot-*`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2106"
        }}>{`#2106`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2107"
        }}>{`#2107`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`armeria-spring-boot-actuator-autoconfigure`}</inlineCode>{` does not refuse to start anymore when `}<inlineCode parentName="li">{`HealthStatusHttpMapper`}</inlineCode>{` is missing. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2104"
        }}>{`#2104`}</a></li>
    </ul>
    <h2 {...{
      "id": "breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#breaking-changes",
        "aria-label": "breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Breaking changes`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Flags.verboseExceptions()`}</inlineCode>{` has been replaced with `}<inlineCode parentName="li">{`verboseExceptionSampler()`}</inlineCode>{` and `}<inlineCode parentName="li">{`verboseExceptionSamplerSpec()`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2111"
        }}>{`#2111`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`Exceptions.isVerbose()`}</inlineCode>{`, which was deprecated previously, has been removed. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2111"
        }}>{`#2111`}</a></li>
      <li parentName="ul">{`You may need to add `}<inlineCode parentName="li">{`io.micrometer:micrometer-registry-prometheus`}</inlineCode>{` or `}<inlineCode parentName="li">{`io.dropwizard.metrics:metrics-json`}</inlineCode>{` after upgrading if you were depending on them transitively. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2107"
        }}>{`#2107`}</a></li>
    </ul>
    <h2 {...{
      "id": "dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#dependencies",
        "aria-label": "dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Netty 4.1.41 -> 4.1.42`}</li>
      <li parentName="ul">{`Netty TCNative BoringSSL 2.0.25 -> 2.0.26`}</li>
      <li parentName="ul">{`Project Reactor 3.2.12 -> 3.3.0`}</li>
      <li parentName="ul">{`Prometheus 0.6.0 -> 0.7.0`}</li>
      <li parentName="ul">{`Retrofit 2.6.1 -> 2.6.2`}</li>
    </ul>
    <h2 {...{
      "id": "thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#thank-you",
        "aria-label": "thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Thank you`}</h2>
    <ThankYou usernames={['anuraaga', 'codefromthecrypt', 'heowc', 'ikhoon', 'jyblue', 'minwoox', 'sivaalli', 'taejs', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      